import React from "react";

export default class Box extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 0
        };
    }

    render() {
        return <div className="box">{this.props.children}</div>;
    }
}