// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import CryptoJS from "crypto-js";
import {getDownloadURL, getStorage, uploadBytesResumable} from "firebase/storage";
import {ref} from "firebase/database";
import { ref as sRef } from 'firebase/storage';
import {writeDatabase} from "./RealtimeDatabase";
import {createUserWithEmailAndPassword} from "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional\
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
export default storage;

export const uploadAvatar = async (file) =>{
    return new Promise((resolve, reject)=>{
        try{
            if (!file) {
                alert("Please upload an image first!");
            }

            const storageRef = sRef(storage, `/files/${getAuth().currentUser.uid}.${file.name.split('.')[1]}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                (err) => {},
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        await writeDatabase('settings/avatarURL', url);
                    });
                }
            );
        } catch (e){
            reject(e);
        }
    })

}

export const SingIn = async (email, password)  =>{
    return new Promise((resolve, reject) => {
       const auth = getAuth();
       signInWithEmailAndPassword(auth, email, password)
           .then((userCredential) => {
               // Signed in
               const user = userCredential.user;
               resolve(user);
               // ...
           })
           .catch((error) => {
               const errorCode = error.code;
               const errorMessage = error.message;
               reject(errorMessage);
           });
   })
}

export const ForgotPassword = async (email)  =>{
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                resolve();
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                reject(errorMessage);
            });
    })
}

export const SingUp = async (email, password)  =>{
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                resolve(user);
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                reject(errorMessage);
            });
    })
}
