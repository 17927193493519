import { getDatabase, ref, onValue, set} from "firebase/database";
import {getAuth} from "firebase/auth";

export const readDatabaseGeneric = async (path) =>{
    return new Promise((resolve, reject)=>{
        try{
            const db = getDatabase();
            const starCountRef = ref(db, process.env.REACT_APP_DB_PATH + getAuth().currentUser.uid + '/' + path);
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();
                resolve(data);
            });
        } catch (e){
            reject(e);
        }
    })

}

export const readDatabase = async (path) =>{
    return new Promise((resolve, reject)=>{
        try{
            const db = getDatabase();
            const starCountRef = ref(db, process.env.REACT_APP_DB_PATH + getAuth().currentUser.uid + '/' + path);
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();
                let retVal = 0;
                if(data == null || data == 'null')
                    retVal = 0;
                else
                    retVal = parseFloat(data);
                resolve(retVal);
            });
        } catch (e){
            reject(e);
        }
    })

}

export const writeDatabase = async (path, data) =>{
    return new Promise((resolve, reject)=>{
        try{
            const db = getDatabase();
            set(ref(db, process.env.REACT_APP_DB_PATH + getAuth().currentUser.uid + '/' + path), data);
            resolve();
        } catch (e){
            reject(e);
        }
    })

}

export const getDataFromApi = async () =>{
    return new Promise((resolve, reject)=>{
        try{
             fetch(
                 process.env.REACT_APP_EXCHANGE_URL)
                 .then(async (res) =>
                 {
                     const data = await res.json();
                     resolve(data.pair.priceUsd);
                 })
                 /*.then((json) => {
                     this.setState({
                         items: json,
                         DataisLoaded: true
                     });

                 })*/
            //resolve(data);
        }catch (e){
            reject(e);
        }
    })
}

export const getSignature = async (account, amountWei, token) =>{
    return new Promise((resolve, reject)=>{
        try{

            const details = {
                'projId': 'candy-mobile-dashboard',
                'chainId': 25,
                'reqPurpose': 'withdraw',
                'account' : account,
                'amount': amountWei,
                'token': token
            };

            let formBody = [];
            for (var property in details) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(details[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            fetch('https://api.candycity.finance/api/gensig', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            }).then(async (res) =>
            {
                const data = await res.json();
                if(data.status === 'success')
                {
                    resolve(data.data);
                } else
                {
                    reject(data.data.error);
                }

            })
        }catch (e){
            reject(e);
        }
    })
}
