import React from "react";
import ButtonLogin from "./images/ButtonLogin.png"

export default class Button extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 0
        };
    }

    render() {
        return (
            /*<div onClick={this.props.handleClick} className="btn">
                <p>Log In</p>
            </div>*/

            <div onClick={this.props.handleClick} className="btn2"><img src={ButtonLogin} alt="myimage" className="loginImageBtn"></img></div>
        );
    }
}