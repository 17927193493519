import React from "react";
import firebaseManager, {uploadAvatar} from "./FirebaseManager";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getDatabase} from "firebase/database";
import {readDatabase, readDatabaseGeneric, writeDatabase, getDataFromApi} from "./RealtimeDatabase";
import InputField from "./InputField";
import {GrApple, GrFacebook} from "react-icons/gr";
import {approveCANDY, depositFund, withdrawFund} from "./Web3Manager";
import ErrorMessage from "./ErrorMessage";
import {Avatar, CircularProgress, IconButton} from "@mui/material";
import ButtonApprove from "./images/ButtonApprove.png";
import ButtonApproved from "./images/ButtonApproved.png";
import ButtonDeposit from "./images/ButtonDeposit.png";
import ButtonWithdraw from "./images/ButtonWithdraw.png";
import ButtonConvert from "./images/RedCandy.png";
import ButtonGoback from "./images/QuitButton.png";
import {Route, Link, Routes, useNavigate} from 'react-router-dom';

import * as path from "path";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const ImgUpload = ({
                       onChange,
                       src
                   }) =>
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload">
            <img for="photo-upload" src={src}/>
        </div>
        <input id="photo-upload" type="file" onChange={onChange}/>
    </label>


const Name = ({
                  onChange,
                  value
              }) =>
    <div className="field">
        <label htmlFor="name">
            name:
        </label>
        <input
            id="name"
            type="text"
            onChange={onChange}
            maxLength="25"
            value={value}
            placeholder="Alexa"
            required/>
    </div>


const Profile = ({
                     onSubmit,
                     src,
                     name,
                     status,
                 }) =>
    <div className="card">
        <form onSubmit={onSubmit}>
            <h1>Profile</h1>
            <label className="custom-file-upload fas">
                <div className="img-wrap">
                    <img for="photo-upload" src={src}/>
                </div>
            </label>
            <div className="name">{name}</div>
            <div className="status">{status}</div>
            <button type="submit" className="submitProfile">Edit Profile</button>
        </form>
    </div>

const Edit = ({
                  onSubmit,
                  children,
              }) =>

    <div className="card">
        <form onSubmit={onSubmit}>
            <h1>Profile Card</h1>
            {children}
            <table>
                <tr>
                    <td>
                        <button type="submit" className="save submitProfile">Save</button>
                    </td>
                    <td>
                       <button className="cancel submitProfile">Cancel</button>
                    </td>
                </tr>
            </table>
        </form>
    </div>

class CardProfile extends React.Component {
    state = {
        file: '',
        imagePreviewUrl: 'https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true',
        name: '',
        status: '',
        active: 'edit'
    }

    photoUpload = async e => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        uploadAvatar(file);
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file);
    }
    editName = async e => {
        const name = e.target.value;
        await writeDatabase('settings/userName', name);
        this.setState({
            name,
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        let activeP = this.state.active === 'edit' ? 'profile' : 'edit';
        this.props.handleSaveProfileClick();
        this.setState({
            active: activeP,
        })
    }

    componentDidMount(){
        readDatabaseGeneric('settings/avatarURL').then((data)=>{
            this.setState({
                imagePreviewUrl: data
            })
        });

        readDatabaseGeneric('settings/userName').then((data)=>{
            this.setState({
                name: data
            })
        });
    }

    render() {
        const {
            imagePreviewUrl,
            name,
            status,
            active
        } = this.state;

        return (
            <div>
                {(active === 'edit') ? (
                    <Edit onSubmit={this.handleSubmit}>
                        <ImgUpload onChange={this.photoUpload} src={imagePreviewUrl}/>
                        <Name onChange={this.editName} value={name}/>
                    </Edit>
                ) : (
                    <Profile
                        onSubmit={this.handleSubmit}
                        src={imagePreviewUrl}
                        name={name}
                        status={status}/>)}

            </div>
        )
    }
}

class ContractCallButtons extends React.Component {
    render() {
        let approved = localStorage.getItem('CANDY_APPROVED');
        return (
            <div className='otherLoginButtons'>
                <table>
                    <tr>
                        <td>
                            <div onClick={this.props.handleApproveClick} className="btn2"><img
                                src={approved ? ButtonApproved : ButtonApprove} alt="blueBtn"
                                className="userProImageBtn"></img></div>
                        </td>
                        <td>
                            <div onClick={this.props.handleDepositClick} className="btn2"><img src={ButtonDeposit}
                                                                                               alt="blueBtn"
                                                                                               className="userProImageBtn"></img>
                            </div>
                            {/* <div onClick={this.props.handleDepositClick} className="btnDepositAndWithdraw">
                                <p>Deposit</p>
                            </div> */}
                        </td>
                        <td>
                            <div onClick={this.props.handleWithdrawClick} className="btn2"><img src={ButtonWithdraw}
                                                                                                alt="greenBtn"
                                                                                                className="userProImageBtn"></img>
                            </div>
                            {/* <div onClick={this.props.handleWithdrawClick} className="btnDepositAndWithdraw">
                                <p>Withdraw</p>
                            </div> */}
                        </td>

                    </tr>
                </table>
            </div>


        );
    }
}

class ProfileInputField extends React.Component {
    constructor() {
        super();
        this.state = {
            type: "text"
        };
    }

    handleInput(e) {
        //const value = e.target.value.replace(/\D/, '');

        this.props.onChange(e.target.value);
    }

    render() {
        return (
            <div>
                <label className='inputFieldLabel'>{this.props.label}</label>
                <input
                    type={this.props.type ? this.props.type : this.state.type}
                    name={this.props.name}
                    onChange={e => this.handleInput(e)}
                    value={this.props.value}
                    className="input"
                />
            </div>
        );
    }
}

class ProfileBox extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 0
        };
    }

    render() {
        return <div className="profileBox">{this.props.children}</div>;
    }
}

export default class UserProfile extends React.Component {

    constructor() {
        super();
        this.state = {
            isFieldsEmpty: true,
            error: {
                amount: false,
                message: "",
                done: false
            },
            amountValue: "",
            lollipopAmount:"",
            user: getAuth().currentUser,
            candy: 0,
            lollipop:0,
            inProgress: false,
            profileEdit: false,
            converter: false,
            price:0,
            covertedValue:0,
            enteredValue:0,
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleDepositClick = this.handleDepositClick.bind(this);
        this.handleWithdrawClick = this.handleWithdrawClick.bind(this);
        this.handleApproveClick = this.handleApproveClick.bind(this);
        this.handleProfileClick = this.handleProfileClick.bind(this);
        this.handleGobackClick = this.handleGobackClick.bind(this);
        this.handleProfileSave = this.handleProfileSave.bind(this);
        this.handleCovertClick = this.handleCovertClick.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.handleLollipopInput = this.handleLollipopInput.bind(this);
        this.handleCandyInput = this.handleCandyInput.bind(this);
        this.clickConverter = this.clickConverter.bind(this);
        this.clickLollipopConverter = this.clickLollipopConverter.bind(this);
        this.tabClickEvent=this.tabClickEvent.bind(this);
        this.tabClickEvent();
    }

    handleInput(value) {
        this.setState(
            {
                amountValue: value
            }
        );
    }

    tabClickEvent(){
        readDatabase('items/token').then(data => {
            this.setState({
                candy: data
            })
        }).catch(err => {
        })

        readDatabase('items/lollipop').then(data => {
            this.setState({
                lollipop:data
            })
        }).catch(err => {
        })

        this.state.lollipopAmount = '';
        this.covertedValue = '';
    }

    handleLollipopInput(value) {
        if(this.state.candy >= value){
            this.covertedValue = parseInt(value * this.price / 0.035);
            this.enteredValue = value;
            this.setState(prevState => ({
                lollipopAmount: value,
                error: {
                    ...prevState.error,
                    amount: false,
                    message: "",
                    done:true
                }
            }));
        }else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: this.state.amountValue === "",
                    message: "Please enter a valid amount",
                    done:false
                }
            }));
        }
    }

    handleCandyInput(value){
        if(this.state.lollipop >= value){
            this.covertedValue = parseInt(value * 0.035 / this.price);
            this.enteredValue = value;
            this.setState(prevState => ({
                lollipopAmount: value,
                error: {
                    ...prevState.error,
                    amount: false,
                    message: "",
                    done:true
                }
            }));
        }else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: this.state.amountValue === "",
                    message: "Please enter a valid amount",
                    done:false
                }
            }));
        }
    }

    //Covert Function
   async clickConverter(){
       if(this.state.candy >= this.enteredValue){
           this.covertedValue = parseInt(this.enteredValue * this.price / 0.035);

        const lollipop = this.state.lollipop + this.covertedValue;
        const candy = this.state.candy - this.state.lollipopAmount;
        this.setState(() =>{
            return{
                lollipop: parseInt(lollipop),
                candy: parseInt(candy)
            }
        });

          await writeDatabase('items/lollipop',lollipop);
          await writeDatabase('items/token',candy);
       }else {
           this.setState(prevState => ({
               error: {
                   ...prevState.error,
                   amount: this.state.amountValue === "",
                   message: "Please enter a valid amount",
                   done:false
               }
           }));
       }
    }

    async clickLollipopConverter(){
        if(this.state.lollipop >= this.enteredValue){
            this.covertedValue = parseInt(this.enteredValue * 0.035 / this.price);

            const candy = this.state.candy + this.covertedValue;
            const lollipop = this.state.lollipop - this.state.lollipopAmount;
            this.setState(() =>{
                return{
                    lollipop: parseInt(lollipop),
                    candy: parseInt(candy)
                }
            });

            await writeDatabase('items/lollipop',lollipop);
            await writeDatabase('items/token',candy);

        }else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: this.state.amountValue === "",
                    message: "Please enter a valid amount",
                    done:false
                }
            }));
        }
    }

    isFieldsEmpty() {
        if (!this.state.emailValue || !this.state.passValue) {
            /*this.setState({
              formHasError: true,
              errorMsg: 'Please fill all details'
            });*/
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: this.state.amountValue === "",
                    message: "Please enter a valid amount"
                }
            }));
            return true;
        } else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: false
                }
            }));
            return false;
        }
    }

    handleDepositClick() {
        this.setState(prevState => ({
            error: {
                ...prevState.error,
                amount: false,
                message: "",
                done: false
            },
            inProgress: true
        }));

        depositFund(this.state.amountValue).then((r) => {

            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: false,
                    message: "CANDY withdrawn successfully!",
                    done: true
                }
            }));
            this.setState({
                candy: r
            });
        }).catch(e => {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: true,
                    message: e
                }
            }));
        }).finally(() => {
            this.setState({

                inProgress: false
            });
        });
    }

    handleApproveClick() {
        this.setState(prevState => ({
            error: {
                ...prevState.error,
                amount: false,
                message: "",
                done: false
            },
            inProgress: true
        }));

        approveCANDY().then(r => {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: false,
                    message: "CANDY approved",
                    done: true
                }
            }));
        }).catch(e => {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: true,
                    message: e
                }
            }));
        }).finally(() => {
            this.setState({

                inProgress: false
            });
        });
    }

    handleProfileClick() {
        this.setState(prevState => ({
            profileEdit: true
        }));
    }

    handleGobackClick() {
        localStorage.setItem('ShowProfile', 'no');
        window.location.reload();
    }

    handleCovertClick(){
        this.setState(prevState => ({
            converter: true
        }));

        getDataFromApi().then((data)=>{
            this.price = data;
        });
    }

    handleProfileSave() {
        this.setState(prevState => ({
            profileEdit: false
        }));
    }

    clickHandler() {
        this.state.lollipopAmount='';
        this.covertedValue = '';
        this.setState(prevState => ({
            converter: false,
            error: {
                ...prevState.error,
                amount: false,
                message: "",
                done: true
            },
        }));

    }

    handleWithdrawClick() {
        this.setState(prevState => ({
            error: {
                ...prevState.error,
                amount: false,
                message: "CANDY withdrawn successfully!",
                done: true
            },
            inProgress: true
        }));
        withdrawFund(this.state.amountValue).then((r) => {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: false,
                    message: "",
                    done: true
                }
            }));
            this.setState({
                candy: r
            });
        }).catch(e => {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    amount: true,
                    message: e
                }
            }));
        }).finally(() => {
            this.setState({

                inProgress: false
            });
        });
    }

    render() {
        const {user, inProgress, candy, profileEdit, converter, lollipop} = this.state;

        getAuth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken)=> {
        }).catch((error)=>{
        });

        if (profileEdit) {
            return (
                <div className="loginContainer">
                    <CardProfile handleSaveProfileClick={this.handleProfileSave}/>
                </div>
            );
        }
        else if(converter){
            return(
                <div className="loginContainer">
                    <ProfileBox>
                        <Tabs>
                            <TabList style={{textAlign:"center"}}>
                                <Tab style={{width:'45%'}} onClick={this.tabClickEvent}>Candy to lollipop</Tab>
                                <Tab style={{width:'45%'}} onClick={this.tabClickEvent}>Lollipop to candy</Tab>
                            </TabList>
                            <TabPanel className='tabPanel'>
                                <ErrorMessage
                                    msg={this.state.error.message}
                                    show={this.state.error.amount}
                                    done={this.state.error.done}
                                />
                                <h4 className='loginOptionText2'>You have : {candy} CANDY in your profile</h4>
                                <h4 className='loginOptionText2'>You have : {lollipop} LOLLIPOP in your profile</h4>
                                <ProfileInputField
                                    onChange={this.handleLollipopInput}
                                    label='Enter candy amount'
                                    name='lollipopAmount'
                                    value={this.state.lollipopAmount}
                                />
                                <h4 className='loginOptionText2'>You will get no : {this.covertedValue} lollipops</h4>
                                <table style={{margin: 'auto', bottom: '20%'}}>
                                    <tr>
                                        <td>
                                            <button type="submit" className="save submitProfile" onClick={this.clickConverter}>Convert</button>
                                        </td>
                                        <td>
                                            <button className="cancel submitProfile" onClick={this.clickHandler}>Cancel</button>
                                        </td>
                                    </tr>
                                </table>
                            </TabPanel>
                            <TabPanel className='tabPanel'>
                                <ErrorMessage
                                    msg={this.state.error.message}
                                    show={this.state.error.amount}
                                    done={this.state.error.done}
                                />
                                <h4 className='loginOptionText2'>You have : {lollipop} LOLLIPOP in your profile</h4>
                                <h4 className='loginOptionText2'>You have : {candy} CANDY in your profile</h4>
                                <ProfileInputField
                                    onChange={this.handleCandyInput}
                                    label='Enter lollipop amount'
                                    name='lollipopAmount'
                                    value={this.state.lollipopAmount}
                                />
                                <h4 className='loginOptionText2'>You will get no : {this.covertedValue} candies</h4>
                                <table style={{margin: 'auto', bottom: '20%'}}>
                                    <tr>
                                        <td>
                                            <button type="submit" className="save submitProfile" onClick={this.clickLollipopConverter}>Convert</button>
                                        </td>
                                        <td>
                                            <button className="cancel submitProfile" onClick={this.clickHandler}>Cancel</button>
                                        </td>
                                    </tr>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </ProfileBox>
                </div>
            );
        }

        else {
            return (
                <div className="loginContainer">
                    <IconButton class="fab fa-instagram">
                        <Avatar onClick={this.handleProfileClick}
                            style={{
                                width: "60px",
                                height: "60px",
                            }}
                        />
                        <br/>
                        <img onClick={this.handleCovertClick}
                             src={ButtonConvert}
                             alt="greenBtn"
                             className="userProImageBtn"></img>
                    </IconButton>
                    <IconButton class="fab fa-instagramLeft">
                        <img onClick={this.handleGobackClick}
                             src={ButtonGoback}
                             alt="greenBtn"
                             className="userProImageBtn"></img>
                    </IconButton>
                    <ProfileBox>
                        {inProgress && <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}}/>}
                        <ErrorMessage
                            msg={this.state.error.message}
                            show={this.state.error.amount}
                            done={this.state.error.done}
                        />


                        <h4 className='loginOptionText2'>Candy City User ID : {user.uid}</h4>
                        <h4 className='loginOptionText2'>You have : {candy} CANDY in your profile</h4>
                        <br/>
                        <ProfileInputField
                            onChange={this.handleInput}
                            label='Enter amount to deposit or withdraw'
                            name='amount'
                            value={this.state.amountValue}
                        />
                        <ContractCallButtons handleDepositClick={this.handleDepositClick}
                                             handleWithdrawClick={this.handleWithdrawClick}
                                             handleApproveClick={this.handleApproveClick}/>
                    </ProfileBox>
                </div>
            );
        }
    }
}
