import React from 'react';

import Box from "./Box";
import UserProfile from "./UserProfile";
import {connectMetamask, connectWalletConnect} from "./Web3Manager";
import ErrorMessage from "./ErrorMessage";
import MetaImage from "./images/walletButton.png";
import WalletImage from "./images/walletconnectButton.png";
import ButtonLogout from "./images/CloseButton.png";
import {IconButton} from "@mui/material";
import {getAuth} from "firebase/auth";

class WalletConnectBox extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 0
        };
    }

    render() {
        return <div className="walletConnectBox">{this.props.children}</div>;
    }
}

class WalletConnectButton extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 0
        };
    }

    render() {
        return (
            <div onClick={this.props.handleClick} className="btn2"><img src={WalletImage} alt="myimage1" className="loginImageBtn"></img></div>
            // <div onClick={this.props.handleClick} className="btnMetamaskConnect">
            //     <p className='walletName'><img className='metamaskIcon' src={walletConnectLogo}/>&nbsp;&nbsp;&nbsp;WalletConnect</p>
            // </div>
        );
    }
}

class MetamaskButton extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 0
        };
    }

    render() {
        return (
        <div onClick={this.props.handleClick} className="btn2"><img src={MetaImage} alt="myimage2" className="loginImageBtn"></img></div>
            // <div onClick={this.props.handleClick} className="btnMetamaskConnect">
            //     <p className='walletName'><img className='metamaskIcon' src={metamaskLogo}/>&nbsp;&nbsp;&nbsp;Metamask</p>
            // </div>
        );
    }
}

export default class ConnectWallets extends React.Component {

    constructor() {
        super();
        localStorage.setItem('ShowProfile', 'no');
        this.state = {
            selectedAccount: undefined,
            error: {
                wallet: false,
                message: ""
            },
        };
        this.handleMetamaskClick = this.handleMetamaskClick.bind(this);
        this.handleGobackClick = this.handleGobackClick.bind(this);
        this.handleWalletConnectClick = this.handleWalletConnectClick.bind(this);
    }

    handleWalletConnectClick() {
        this.setState(prevState => ({
            error: {
                ...prevState.error,
                wallet: false,
            }
        }));
        connectWalletConnect().then(r => {
            localStorage.setItem('ShowProfile', 'yes');
            this.setState(prevState => ({
                selectedAccount: r,
                error: {
                    ...prevState.error,
                    wallet: false,
                },
            }));
        }).catch(e => {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    wallet: true,
                    message: e.message
                }
            }));
        });
    }

    async handleGobackClick() {
        await getAuth().signOut();
    }

    handleMetamaskClick() {
        this.setState(prevState => ({
            error: {
                ...prevState.error,
                wallet: false,
            }
        }));
        connectMetamask().then(r => {
            localStorage.setItem('ShowProfile', 'yes');
            this.setState(prevState => ({
                selectedAccount: r,
                error: {
                    ...prevState.error,
                    wallet: false,
                },
            }));
        }).catch(e => {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    metamask: true,
                    wallet: e.message
                }
            }));
        });
    }

    render() {
        const showProfile = localStorage.getItem('ShowProfile') === 'yes';
        if(showProfile){
            return (
               <UserProfile/>
            );
        } else {
        return (
            <div className="loginContainer">
                <Box>
                    <ErrorMessage
                    msg={this.state.error.message}
                    show={this.state.error.wallet}/>
                    <IconButton class="fab fa-instagramLeft">
                        <img onClick={this.handleGobackClick}
                             src={ButtonLogout}
                             alt="greenBtn"
                             className="userProImageBtn"></img>
                    </IconButton>
                    <label className='loginOptionText1'>Connect to your wallet</label>
                    <MetamaskButton  handleClick={this.handleMetamaskClick}/>
                    <WalletConnectButton handleClick={this.handleWalletConnectClick}/>
                </Box>
            </div>
        );
        }
    }
}
