import Web3 from 'web3';
import { ethers, providers } from 'ethers';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { readDatabase, writeDatabase, getSignature } from "./RealtimeDatabase";
import { getAuth } from "firebase/auth";
import gameABI from "./gameConteactABI.json"
import tokenABI from "./tokenContractABI.json"
let _selectedAccount;

let _provider;

export const connectWalletConnect = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const provider = new WalletConnectProvider({
                rpc: {
                    25: 'https://rpc.vvs.finance/',
                },
                infuraId: "9aa3d95b3bc440fa88ea12eaa4456161",
            });

            //  Enable session (triggers QR Code modal)
            await provider.enable();

            const web3Provider = new providers.Web3Provider(provider);

            _provider = web3Provider;
            _selectedAccount = web3Provider.provider.accounts[0];
            resolve(_provider);

        } catch (e) {
            reject(e.message);
        }
    });
}
export const connectMetamask = async () => {

    return new Promise((resolve, reject) => {
        let provider = window.ethereum;

        if (typeof provider !== 'undefined') {
            provider
                .request({ method: 'eth_requestAccounts' })
                .then((accounts) => {
                    _selectedAccount = accounts[0];
                    const { ethereum } = window;
                    _provider = new ethers.providers.Web3Provider(ethereum);
                    resolve(_selectedAccount)
                })
                .catch((err) => {
                    reject(err);
                });

            window.ethereum.on('accountsChanged', function (accounts) {
                _selectedAccount = accounts[0];
            });


            window.ethereum.on('message', function (type, data) {
            });
        } else {
            reject("Browser doesn't compatible")
        }
    })

}

export const approveCANDY = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let amountWei = ethers.utils.parseEther('1000000000000000');
            const signer = _provider.getSigner();
            const tokenContract = new ethers.Contract(process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS, tokenABI, signer);

            let gas1 = await tokenContract.estimateGas.approve(process.env.REACT_APP_GAME_CONTRACT_ADDRESS, amountWei);

            let approveReq = await tokenContract.approve(process.env.REACT_APP_GAME_CONTRACT_ADDRESS, amountWei, { 'gasLimit': parseInt(gas1.mul(13).div(10)) });
            let approveReceipt = await approveReq.wait();
            localStorage.setItem('CANDY_APPROVED', 'Yes');
            resolve(approveReceipt);
        } catch (e) {
            reject(e.message);
        }
    });
}

export const depositFund = async (amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { ethereum } = window;

            if (ethereum) {

                let approved = localStorage.getItem("CANDY_APPROVED");

                if (approved == 'Yes') {
                    if (amount.toString() == '') {
                        reject('Please enter a valid amount');
                    }
                    let amountWei = ethers.utils.parseEther(amount);
                    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
                    const signer = _provider.getSigner();

                    const gameContract = new ethers.Contract(process.env.REACT_APP_GAME_CONTRACT_ADDRESS, gameABI, signer);
                    let gas2 = await gameContract.estimateGas.deposit(amountWei);

                    let result = await gameContract.deposit(amountWei, { 'gasLimit': gas2.mul(13).div(10) });

                    let receipt = await result.wait();

                    if (receipt.status == 1) {
                        let data = await readDatabase('items/token');
                        await writeDatabase('items/token', data + parseFloat(amount));
                        let dataNew = await readDatabase('items/token');
                        resolve(dataNew);
                    } else {
                        reject("Approval failed");
                    }
                } else {
                    reject("You haven't approved CANDY");
                }



            } else {
                reject("Ethereum object does not exist");
            }
        } catch (e) {
            reject(e.message);
        }
    })

}

export const withdrawFund = async (amount) => {
    return new Promise(async (resolve, reject) => {

        readDatabase('items/token').then(async (data) => {
            if (parseFloat(data) >= parseFloat(amount)) {
                try {
                    const { ethereum } = window;

                    if (ethereum) {
                        if (amount.toString() == '') {
                            reject('Please enter a valid amount');
                        }

                        let amountWei = Web3.utils.toWei(amount);
                        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

                        const token = await getAuth().currentUser.getIdToken(true);

                        await getSignature(accounts[0], amountWei, token).then(async ({ signature, expireTimestamp }) => {
                            const signer = _provider.getSigner();

                            const gameContract = new ethers.Contract(process.env.REACT_APP_GAME_CONTRACT_ADDRESS, gameABI, signer);

                            let result = await gameContract.withdraw(amountWei, expireTimestamp, signature, { 'gasLimit': 1000000 });

                            let receipt = await result.wait();

                            await writeDatabase('items/token', data - parseFloat(amount));
                            let dataNew = await readDatabase('items/token');

                            resolve(dataNew);
                        }).catch(e => {
                            reject(JSON.stringify(e));
                        })
                    } else {
                        reject("Ethereum object does not exist");
                    }
                } catch (e) {
                    reject(e.message);
                }
            } else {
                reject('Insufficient CANDY');
            }
        }).catch(e => {
            reject(e.message);
        })

    })

}
