import './App.css';
import Login from "./Login";
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom'
import ConnectWallets from "./ConnectWallets";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useEffect, useState} from "react";

function App() {
    const [currentUser, setCurrentUser] = useState(null)
    useEffect(() => {
        onAuthStateChanged(getAuth(), (user) => {
            setCurrentUser(user)
        })
    }, [])
  return (
      <Router>
      <div className="App">
          <>
              <Routes>
                  <Route path='/' element={<Login />} />
                  <Route path='/ConnectWallets' element={<ConnectWallets />} />
              </Routes>
          </>
      </div>
      </Router>
  );
}

export default App;
