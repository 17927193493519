import React from "react";

export default class ErrorMessage extends React.Component {
    constructor() {
        super();
        this.state = {
            count: 11
        };
    }

    render() {
        return (
            <div className={this.props.show ? "errorMsg" : "hideErrorMsg"}>
                <h3>{this.props.msg}</h3>
            </div>
        );
    }
}