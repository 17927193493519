import React from 'react';
import {GrApple} from "react-icons/gr";
import {GrFacebook} from "react-icons/gr";
import {ForgotPassword, SingIn, SingUp} from "./FirebaseManager";
import Box from "./Box";
import ErrorMessage from "./ErrorMessage";
import Button from "./Button";
import {FacebookAuthProvider, getAuth, OAuthProvider, onAuthStateChanged, signInWithPopup} from "firebase/auth";
import ConnectWallets from "./ConnectWallets";
import {signInWithCredential} from "@firebase/auth";
import InputField from "./InputField";
import ButtonApproved from "./images/ButtonApproved.png";
import ButtonApprove from "./images/ButtonApprove.png";
import ButtonDeposit from "./images/ButtonDeposit.png";
import ButtonWithdraw from "./images/ButtonWithdraw.png";
import ButtonLogin from "./images/ButtonLogin.png";
import ButtonRegister from "./images/ButtonRegister.png";
import SubmitButton from "./images/SubmitButton.png";




class AppleLoginButton extends React.Component {
    render() {
        return (
            <div className='otherLoginButtons'>
                <table>
                    <tr>
                        <td>
                            <div onClick={this.props.handleAppleClick} className="btnAppleLogin">
                                <GrApple/>
                            </div>
                        </td>
                        <td>
                            <div onClick={this.props.handleFacebookClick} className="btnFacebookLogin">
                                <GrFacebook/>
                            </div>
                        </td>

                    </tr>
                </table>
            </div>


        );
    }
}

class RegisterButton extends React.Component {
    render() {
        //let approved = localStorage.getItem('CANDY_APPROVED');
        return (
            <div className='registerButton'>
                <div onClick={this.props.handleRegisterClick} className="btn2"><img img src={ButtonRegister} alt="myimage" className="loginImageBtn"></img>
                </div>
            </div>
        );
    }
}

class ResetPasswordSubmitButton extends React.Component {
    render() {
        //let approved = localStorage.getItem('CANDY_APPROVED');
        return (
            <div className='registerButton'>
                <div onClick={this.props.handleRegisterClick} className="btn2"><img img src={SubmitButton} alt="myimage" className="loginImageBtn"></img>
                </div>
            </div>
        );
    }
}


export default class Login extends React.Component {

    constructor() {
        super();
        this.state = {
            isFieldsEmpty: true,
            isEmailValid: false,
            emailFieldLabel: "email",
            passFieldLabel: "password",
            regEmailFieldLabel: "reg-email",
            resetPasswordEmailFieldLabel: "Enter your email address",
            regPassFieldLabel: "reg-password",
            reEnterPassFieldLabel: "re-enter password",
            user:undefined,
            error: {
                email: false,
                emailField: false,
                pass: false,
                message: ""
            },
            emailValue: "",
            passValue: "",
            regEmailValue:"",
            resetPassEmailValue:"",
            regPassValue:"",
            reEnterPassValue:"",
            signIn:false,
            register:false
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleAppleClick = this.handleAppleClick.bind(this);
        this.handleFacebookClick = this.handleFacebookClick.bind(this);
        this.handleRegisterClick = this.handleRegisterClick.bind(this);
        this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
        this.handleRegisterDataClick = this.handleRegisterDataClick.bind(this);
        this.handleResetPasswordDataClick = this.handleResetPasswordDataClick.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
    }


    handleInput(field, value) {
        switch (field) {
            case "email":
                this.setState(
                    {
                        emailValue: value
                    },
                    () => this.isEmailValid()
                );
                break;
            case "password":
                this.setState(
                    {
                        passValue: value
                    },
                    () => this.isFieldsEmpty()
                );
                break;
            case "reg-email":
                this.setState(
                    {
                        regEmailValue: value
                    },
                    () => this.isEmailValid()
                );
                break;
            case "reg-password":
                this.setState(
                    {
                        regPassValue: value
                    },
                    () => this.isRegFieldsEmpty()
                );
                break;
            case "re-enter password":
                this.setState(
                    {
                        reEnterPassValue: value,
                    },
                    () => this.isRegFieldsEmpty()
                );
                break;
            case "Enter your email address":
                this.setState(
                    {
                        resetPassEmailValue: value,
                    },
                    () => this.isResetPasswordFieldsEmpty()
                );
                break;
            default:
                break;
        }
    }

    isEmailValid() {
         if (this.state.emailValue || this.state.regEmailValue) {
             let email = '';
            if(this.state.emailValue != ''){
                email = this.state.emailValue;
            }else{
                email = this.state.regEmailValue;
            }

            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");

            if (
                lastAtPos < lastDotPos &&
                lastAtPos > 0 &&
                email.indexOf("@@") == -1 &&
                lastDotPos > 2 &&
                email.length - lastDotPos > 2
            ) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        email: false
                    }
                }));
            } else {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        email: true,
                        message: "Invalid Email Format"
                    }
                }));
                /*this.setState({
                  formHasError: true,
                  errorMsg: 'Invalid Email Format'
                });*/
            }
        } else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    email: false
                }
            }));
        }
    }

    isResetPasswordFieldsEmpty() {
        if (!this.state.resetPassEmailValue) {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    pass: this.state.resetPassEmailValue === "",
                    message: "Please enter your email address"
                }
            }));
            return true;
        } else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    pass: false
                }
            }));
            return false;
        }
    }

    isRegFieldsEmpty() {
        if (!this.state.regEmailValue || !this.state.regPassValue || !this.state.reEnterPassValue) {
            /*this.setState({
              formHasError: true,
              errorMsg: 'Please fill all details'
            });*/
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    pass: this.state.regPassValue === "",
                    email: this.state.regEmailValue === "",
                    message: "Please fill all details"
                }
            }));
            return true;
        } else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    pass: false
                }
            }));
            return false;
        }
    }

    isFieldsEmpty() {
        if (!this.state.emailValue || !this.state.passValue) {
            /*this.setState({
              formHasError: true,
              errorMsg: 'Please fill all details'
            });*/
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    pass: this.state.passValue === "",
                    email: this.state.emailValue === "",
                    message: "Please fill all details"
                }
            }));
            return true;
        } else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    pass: false
                }
            }));
            return false;
        }
    }

    handleAppleClick() {
        const provider = new OAuthProvider('apple.com')
        provider.addScope('email');
        provider.addScope('name');

        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result);

                signInWithCredential(auth, credential)
                    .then((userCredential) => {
                        // Signed in
                        const _user = userCredential.user;
                        this.setState(prevState => ({
                            user: _user
                        }));
                        // ...
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // ...
                    });
                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        email: true,
                        pass: true,
                        message: errorMessage
                    }
                }));

                // ...
            });
    }

    handleFacebookClick() {
        const provider = new FacebookAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                const credential = FacebookAuthProvider.credentialFromResult(result);

                signInWithCredential(auth, credential)
                    .then((userCredential) => {
                        // Signed in
                        const _user = userCredential.user;
                        this.setState(prevState => ({
                            user: _user
                        }));
                        // ...
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // ...
                    });
                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        email: true,
                        pass: true,
                        message: errorMessage
                    }
                }));

                // ...
            });
    }

    handleClick() {
        if (
            !this.isFieldsEmpty() &&
            !this.state.error.email &&
            !this.state.error.pass
        ) {
            SingIn(this.state.emailValue, this.state.passValue).then((_user) => {
                this.setState(prevState => ({
                    user: _user
                }));
            }).catch(msg => {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        email: true,
                        pass: true,
                        message: msg
                    }
                }));
            });
        }
    }

    handleResetPasswordDataClick() {
            if (
                !this.isResetPasswordFieldsEmpty() &&
                !this.state.error.email &&
                !this.state.error.pass
            ) {
                ForgotPassword(this.state.resetPassEmailValue).then((_ser) => {
                    this.setState(prevState => ({
                        register: false,
                        resetPassword: false
                    }));
                }).catch(msg => {
                    this.setState(prevState => ({
                        error: {
                            ...prevState.error,
                            email: true,
                            pass: true,
                            message: msg
                        }
                    }));
                });
            }
    }

    handleRegisterDataClick() {
        if (this.state.regPassValue === this.state.reEnterPassValue) {
            if (
                !this.isRegFieldsEmpty() &&
                !this.state.error.email &&
                !this.state.error.pass
            ) {
                SingUp(this.state.regEmailValue, this.state.regPassValue,).then((_user) => {
                    this.setState(prevState => ({
                        user: _user
                    }));
                }).catch(msg => {
                    this.setState(prevState => ({
                        error: {
                            ...prevState.error,
                            email: true,
                            pass: true,
                            message: msg
                        }
                    }));
                });
            }
        } else {
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    email: true,
                    pass: true,
                    message: 'Re entered password should be same as password'
                }
            }));
        }
    }

    handleRegisterClick() {
        this.setState(prevState => ({
            register: true,
            resetPassword: false
        }));
    }

    handleResetPasswordClick() {
        this.setState(prevState => ({
            register: false,
            resetPassword: true
        }));
    }

    clickHandler() {
        this.setState(prevState => ({
            register: false,
            resetPassword: false
        }));
    }

    render() {
        const user = getAuth().currentUser;

        onAuthStateChanged(getAuth(), async (user) => {
            if (!user) {
                await getAuth().signOut();
            }
        });

        const {register}=this.state;
        const {resetPassword}=this.state;
        if(!user) {
            if(register){
                return(
                    <div className="loginContainer">
                        <Box>
                            <div style={{textAlign:'right', marginRight:'5%'}}>
                                <button className="cancelRegForm" onClick={this.clickHandler}>❌</button>
                            </div>
                            <ErrorMessage
                                msg={this.state.error.message}
                                show={this.state.error.email || this.state.error.pass}
                            />
                            <label className='loginOptionText1'>Register !</label>
                            <InputField
                                onChange={this.handleInput}
                                label={this.state.regEmailFieldLabel}
                                name={this.state.regEmailFieldLabel}
                                value={this.state.regEmailValue}
                            />
                            <InputField
                                onChange={this.handleInput}
                                label={this.state.regPassFieldLabel}
                                name={this.state.regPassFieldLabel}
                                value={this.state.regPassValue}
                                type={this.state.passFieldLabel}
                            />

                            <InputField
                                onChange={this.handleInput}
                                label={this.state.reEnterPassFieldLabel}
                                name={this.state.reEnterPassFieldLabel}
                                value={this.state.reEnterPassValue}
                                type={this.state.passFieldLabel}
                            />
                            <div style={{margin: 'auto'}}>
                                <RegisterButton handleRegisterClick={this.handleRegisterDataClick}/>
                            </div>
                        </Box>
                    </div>
                )
           }
            else if(resetPassword){
                return(
                    <div className="loginContainer">
                        <Box>
                            <div style={{textAlign:'right', marginRight:'5%'}}>
                                <button className="cancelRegForm" onClick={this.clickHandler}>❌</button>
                            </div>
                            <ErrorMessage
                                msg={this.state.error.message}
                                show={this.state.error.email}
                            />
                            <label className='loginOptionText1'>Reset Password !</label>
                            <InputField
                                onChange={this.handleInput}
                                label={this.state.resetPasswordEmailFieldLabel}
                                name={this.state.resetPasswordEmailFieldLabel}
                                value={this.state.resetPassEmailValue}
                            />
                            <div style={{margin: 'auto'}}>
                                <ResetPasswordSubmitButton handleRegisterClick={this.handleResetPasswordDataClick}/>
                            </div>
                        </Box>
                    </div>
                )
            }
            else {
                return (
                    <div className="loginContainer">
                        <Box>
                            <ErrorMessage
                                msg={this.state.error.message}
                                show={this.state.error.email || this.state.error.pass}
                            />
                            <label className='loginOptionText1'>Hello Again!</label>
                            <InputField
                                onChange={this.handleInput}
                                label={this.state.emailFieldLabel}
                                name={this.state.emailFieldLabel}
                                value={this.state.emailValue}
                            />
                            <InputField
                                onChange={this.handleInput}
                                label={this.state.passFieldLabel}
                                name={this.state.passFieldLabel}
                                value={this.state.passValue}
                                type={this.state.passFieldLabel}
                            />
                            <table style={{margin: 'auto', bottom: '20%'}}>
                                <tr>
                                    <td>
                                        <Button handleClick={this.handleClick}/>
                                    </td>
                                    <td>
                                        <RegisterButton handleRegisterClick={this.handleRegisterClick}/>
                                    </td>
                                </tr>
                            </table>
                            <label className='forgotpassword' onClick={this.handleResetPasswordClick}>Forgot Password - Click here</label>
                            <br/>
                            <label className='loginOptionText3'>--------- Or Continue
                                with --------- </label>

                            <AppleLoginButton handleAppleClick={this.handleAppleClick}
                                              handleFacebookClick={this.handleFacebookClick}/>
                        </Box>
                    </div>
                );
            }
        } else {
            return (
                <ConnectWallets/>
            );
        }

    }
}
