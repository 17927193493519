import React from "react";

export default class InputField extends React.Component {
    constructor() {
        super();
        this.state = {
            type: "text"
        };
    }

    handleInput(e) {
        const value = e.target.value;
        this.props.onChange(this.props.name, value);
    }

    render() {
        return (
            <div>
                {/*<h3 >{this.props.label}</h3>*/}
                <input
                    type={this.props.type ? this.props.type : this.state.type}
                    name={this.props.name}
                    onChange={e => this.handleInput(e)}
                    value={this.props.value}
                    className="input"
                    placeholder={this.props.name == 'email' ? 'Email' : (this.props.name == 'password' ? 'Password' :
                        (this.props.name == 'reg-email' ? 'Email': (this.props.name == 'reg-password' ? 'Password' : ( this.props.name ==  'Enter your email address' ? 'Enter your email address' : 'Re Enter Password'))))}
                />
            </div>
        );
    }
}
